<template>
  <div class="modal fade" ref="mdlTicket" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content" style="border:none">
        <div class="modal-header bg-success">
          <h4 class="modal-title text-white">
            Ticket de soporte ({{ ticket.id }})
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="input-group">
                <span class="input-group-text">Cliente</span>
                <input 
                  type="text"
                  class="form-control"
                  v-model="ticket.nombre"
                  readonly
                />
              </div>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-6">
              <label>Fecha programada</label>
              <input
                type="datetime-local"
                class="form-control"
                placeholder="YYYY-MM-DD HH:MM:SS"
                v-model="ticket.fecha_programada"
                :disabled="ticket.mt_cierre != null || ticket.cancelado"
              />
            </div>
            <div class="col-md-6">
              <label>Tiempo estimado</label>
              <div class="row text-center">
                <div class="col-sm-4 col-4">
                  <input
                    name="tiempoDias" type="number"
                    class="form-control text-center" ref="tiempoDias"
                    v-model="ticket.tiempo_estimado_dias"
                    min="0" max="365"
                    placeholder="0"
                    :disabled="ticket.mt_cierre != null || ticket.cancelado"
                  />
                  <label>Dias</label>
                </div>
                <div class="col-sm-4 col-4">
                  <input
                    name="tiempoHoras" type="number"
                    class="form-control text-center" ref="tiempoHoras"
                    v-model="ticket.tiempo_estimado_horas"
                    min="0" max="23"
                    placeholder="0"
                    :disabled="ticket.mt_cierre != null || ticket.cancelado"
                  />
                  <label>Horas</label>
                </div>
                <div class="col-sm-4 col-4">
                  <input
                    name="tiempoMinutos" type="number"
                    class="form-control text-center" ref="tiempoMinutos"
                    v-model="ticket.tiempo_estimado_minutos" 
                    min="0" max="59"
                    placeholder="0"
                    :disabled="ticket.mt_cierre != null || ticket.cancelado"
                  />
                  <label>Minutos</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label>Prioridad</label>
              <select
                name="prioridad"
                class="form-select"
                v-model="ticket.prioridad"
                :disabled="ticket.mt_cierre != null || ticket.cancelado"
              >
                <option value="3" style="color: green" selected>Normal</option>
              <option value="2" style="color: orange">Media</option>
                <option value="1" style="color: red; font-weight: bold">URGENTE</option>
              </select>
            </div>
            <div class="col-md-4">
              <label>Estado</label>
              <span
                class="form-control text-white fw-semibold text-center"
                :class="{
                  'bg-secondary': evento.estado == 1,
                  'bg-success': evento.estado == 2,
                  'bg-primary': evento.estado == 3,
                  'bg-danger': evento.estado == 4,
                }"
              >
                {{ evento.estado == 1 ? 'Pendiente' : '' }}
                {{ evento.estado == 2 ? 'En proceso' : '' }}
                {{ evento.estado == 3 ? 'Completado' : '' }}
                {{ evento.estado == 4 ? 'Cancelado' : '' }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12" v-show="ticket.mt_inicio">
              <label>Tiempo de sesión</label>
              <div class="row">
                <div class="col-sm-6">
                  <div class="input-group">
                    <div class="input-group-text">Inicio</div>
                    <input
                      type="text"
                      class="form-control text-center"
                      :value="ticket.mt_inicio"
                      readonly
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="input-group">
                    <div class="input-group-text">Fin</div>
                    <input
                      type="text"
                      class="form-control text-center"
                      :value="ticket.mt_cierre"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-md-6">
                  <button
                    @click="sesionesSoporte()"
                    class="btn btn-light"
                    v-if="ticket.id != null"
                  >
                    <i class="mdi mdi-account-clock-outline"></i>
                    Sesiones de soporte
                  </button>
                </div>
                <div class="col-md-6 text-right">
                  <div class="input-group">
                    <span class="input-group-text">
                      <i class="mdi mdi-clock-outline"></i>
                      &nbsp;
                      Tiempo soporte
                    </span>
                    <input 
                      type="text"
                      class="form-control text-center"
                      :value="tiempo_soporte"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-3">
              <div class="form-check form-check-success">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="mode"
                  :id="'para-todos-ticket-'+evento.id"
                  :checked="evento.todos"
                  @change="evento.todos = !evento.todos"
                  :disabled="evento.editable"
                />
                <label
                  class="form-check-label"
                  :for="'para-todos-ticket-'+evento.id"
                >
                  Para todos
                </label>
              </div>
            </div>
            <div class="col-md-9">
              <p v-show="!evento.todos">
                <i class="mdi mdi-arrow-left-thick"></i>
                Si activas esta opción, el evento será
                visible para todos los usuarios y roles.
              </p>
            </div>
          </div>
          <div class="row" v-if="!evento.todos">
            <div class="col-md-6">
              <label for="">Usuarios</label>
              <multiselect
                v-model="evento.ids_usuarios"
                placeholder="Buscar Usuario"
                selectLabel="Presione enter para seleccionar"
                selectedLabel="Seleccionado"
                deselectLabel="Presione enter para eliminar"
                :disabled="evento.editable"
                label="nombre"
                track-by="id"
                :options="usuarios"
                :multiple="true"
                :taggable="false"
              ></multiselect>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="masOpciones()"
            class="btn btn-default text-dark"
            v-if="ticket.id != null"
          >
            Más opciones
          </button>
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
          <button
            class="btn btn-danger"
            v-if="ticket.mt_cierre == null && !ticket.cancelado"
            @click="cancelarSoporte()"
          >
            <i class="mdi mdi-cancel font-size-15"></i>
            Cancelar
          </button>
          <button
            class="btn btn-primary"
            v-if="ticket.mt_inicio == null && !ticket.completado && !ticket.cancelado"
            @click="iniciarSoporte()"
          >
            <i class="mdi mdi-play-outline font-size-15"></i>
            Iniciar soporte
          </button>
          <button
            class="btn btn-warning"
            v-if="ticket.mt_inicio != null && ticket.mt_cierre == null && !ticket.completado && !ticket.cancelado"
            @click="!ticket.bpausa ? pausarSoporte() : reanudadSoporte()"
          >
            <i 
              class="mdi font-size-15"
              :class="!ticket.bpausa ? 'mdi-pause' : 'mdi-play-outline'"
            ></i>
            {{ !ticket.bpausa ? 'Pausar' : 'Reanudad' }} soporte
          </button>
          <button
            class="btn btn-dark"
            v-if="ticket.mt_inicio != null && ticket.mt_cierre == null && !ticket.completado && !ticket.cancelado"
            @click="finalizarSoporte()"
          >
            <i class="mdi mdi-stop font-size-15"></i>
            finalizar soporte
          </button>
          <button
            class="btn btn-success"
            @click="actualizar()"
            v-if="ticket.mt_cierre == null && !ticket.cancelado"
            :disabled="bandera_spinner"
          >
            <i
              class="mdi"
              :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
            ></i>
            Actualizar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TicketSoporteSrv from "@/services/TicketSoporteSrv.js"
import SesionTicketSoporteSrv from '@/services/SesionTicketSoporteSrv.js'
import EventoCalendarioSrv from "@/services/EventoCalendarioSrv"
import UsuarioSrv from '@/services/UsuarioSrv.js'
import Modal from 'bootstrap/js/dist/modal'
import Multiselect from 'vue-multiselect'
import Swal from 'sweetalert2'
import moment from 'moment'
export default {
  name: 'MdlTicket',
  components: { Multiselect },
  data() {
    return {
      id_evento: null,
      id_ticket: null,

      evento: {
        titulo: '', 
        descripcion: '',
        fecha_programada: '',
        tiempo_estimado: 0,
        tiempo_estimado_dias: 0,
        tiempo_estimado_horas: 1,
        tiempo_estimado_minutos: 0,
        id_calendario: null,
        id_instalacion: null,
        id_soporte: null,
        todos: true, 
        estado: 1,
        editable: true,

        ids_usuarios: []
      },

      ticket: {
        id: null,
        id_cliente: null,
        numero_cliente: null,
        correo: '',
        id_cpe: null,
        nombre: '',
        telefono: '',
        nota_cliente: '',
        nota_soporte: '',
        completado: 0,
        prioridad: 3,
        latitud: 0,
        longitud: 0,
        fecha_programada: '',
        tiempo_estimado: 0,
        tiempo_estimado_dias: 0,
        tiempo_estimado_horas: 1,
        tiempo_estimado_minutos: 0,
        telefonos: [],
        sesiones_soporte: [],
        cancelado: 0
      },

      usuarios: [],
      bandera_spinner: false,
    }
  },
  computed: {
    tiempo_soporte: function() {
      let self = this, segundos = 0

      self.ticket.sesiones_soporte.forEach(sesion => {
        if(sesion.fin != null ) {
          let finicial = moment(sesion.inicio)
          let ffinal = moment(sesion.fin)
          let dsegundos = ffinal.diff(finicial, 'seconds')

          segundos += dsegundos
        }
      })

      var dias = Math.floor(segundos / 86400)
      var horas = Math.floor((segundos / 3600) % 24)
      var minutos = Math.floor((segundos / 60) % 60)

      return dias+' dia '+horas+' hrs '+minutos+' min'
    }
  },
  methods: {
    actualizar: function() {
      var self = this 
      self.actualizarEvento(function() {
        self.actualizarSoporte()
      })
    },
    actualizarSoporte() {
      var self = this

      self.bandera_spinner = true

      let tiempo = self.calcularDiasASegundos(self.ticket.tiempo_estimado_dias) +
                  self.calcularHorasASegundos(self.ticket.tiempo_estimado_horas) +
                  self.calcularMinutosASegundos(self.ticket.tiempo_estimado_minutos)

      let datos = {
        fecha_programada: moment(self.ticket.fecha_programada, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
        tiempo_estimado: tiempo,
        prioridad: self.ticket.prioridad,
        completado: self.ticket.completado,
        id_evento: self.id_evento
      }

      TicketSoporteSrv.actualizarTicketCalendario(self.id_ticket, datos).then(response => {
        iu.msg.success('Se actualizó correctamente los datos del soporte de '+self.ticket.nombre)
        this.$emit('ticket:actualizado')
        this.cargarTicketSoporte(self.id_ticket)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se actualizo el soporte de '+self.ticket.nombre
        }
        iu.msg.error(mensaje)
        console.log(error)
        this.$emit('ticket:actualizado')
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    actualizarEvento(callback) {
      var self = this

      self.bandera_spinner = true

      let total_ids = self.evento.ids_usuarios.length

      if( !self.evento.todos && total_ids == 0 ) {
        iu.msg.warning('Es necesario registrar al menos un "Usuario"')
        self.bandera_spinner = false
        return
      }

      let tiempo = self.calcularDiasASegundos(self.evento.tiempo_estimado_dias) +
                  self.calcularHorasASegundos(self.evento.tiempo_estimado_horas) +
                  self.calcularMinutosASegundos(self.evento.tiempo_estimado_minutos)

      let datos = {
        id: self.evento.id,
        fecha_programada: moment(self.evento.fecha_programada, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
        tiempo_estimado: tiempo,
        todos: self.evento.todos,
        ids_usuarios: self.evento.ids_usuarios
      }

      EventoCalendarioSrv.actualizar(datos).then(response => {
        this.cargarEvento(self.id_evento)
        if(callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se actualizo el soporte de '+self.evento.nombre
        }
        iu.msg.error(mensaje)
        console.log(error)
        this.$emit('evento:actualizado')
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    calcularDiasASegundos(dias){
      return dias * 86400
    },
    
    calcularHorasASegundos(horas){
      return horas * 3600
    },

    calcularMinutosASegundos(minutos){
      return minutos * 60
    },

    cargarEvento: function(idEvento) {
      var self = this
      
      EventoCalendarioSrv.eventoJSON(idEvento, { con_ids_usuarios: true, con_calendario: true }).then(response=>{
        self.evento = response.data

        self.evento.tiempo_estimado_dias = self.convertidorSegundos_a_DHM(self.evento.tiempo_estimado )[0]
        self.evento.tiempo_estimado_horas = self.convertidorSegundos_a_DHM(self.evento.tiempo_estimado )[1]
        self.evento.tiempo_estimado_minutos = self.convertidorSegundos_a_DHM(self.evento.tiempo_estimado )[2]

        self.evento.editable = self.evento.estado == 4 || self.evento.estado == 3 ? true : false
      }).catch(error=>{
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el evento de soporte'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarTicketSoporte: function(idTicket) {
      var self = this
      
      TicketSoporteSrv.ticketJSON(idTicket).then(response=>{
        self.ticket = response.data

        if(self.ticket.cpe.router == null) self.ticket.cpe.router = { nombre: '' }

        self.ticket.tiempo_estimado_dias = self.convertidorSegundos_a_DHM(self.ticket.tiempo_estimado )[0]
        self.ticket.tiempo_estimado_horas = self.convertidorSegundos_a_DHM(self.ticket.tiempo_estimado )[1]
        self.ticket.tiempo_estimado_minutos = self.convertidorSegundos_a_DHM(self.ticket.tiempo_estimado )[2]

        self.posicionInicial = {
          lat: parseFloat(self.ticket.latitud),
          lng: parseFloat(self.ticket.longitud)
        }
      }).catch(error=>{
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el ticket de soporte'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarUsuarios: function(callback) {
      var self = this

      let params = { todos: true }

      UsuarioSrv.usuariosJSON(params).then(response => {
        let usuarios = response.data

        self.usuarios = []
        self.evento.ids_usuarios = []
        usuarios.forEach(usu => {
          let datos = {
            id: usu.id,
            nombre: usu.nombre+' '+(usu.apellido_paterno || '')+' '+(usu.apellido_materno || '')
          }
          self.usuarios.push(datos)
        });
        
        if(callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los usuarios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cancelarSoporte: function() {
      var self = this,
        ticket = self.ticket

      var modal = Modal.getInstance(self.$refs.mdlTicket)
      modal.hide()

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      });

      swal.fire({
        title: 'Cancelar ticket de soporte',
        html: '¿Desea cancelar el ticket de soporte del cliente <br> <strong>' + ticket.nombre + '</strong>?',
        icon: "warning",
        cconfirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          TicketSoporteSrv.cancelar(ticket.id).then(response=>{
            swal.fire("Cancelado!", "Se a cancelado correctamente", "success")
            this.$emit('ticket:actualizado')
            this.cargarTicketSoporte(self.id_ticket)
            self.cargarUsuarios(function() {
              self.cargarEvento(self.id_evento)
            })
            var modal = new Modal(self.$refs.mdlTicket)
            modal.show()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo cancelar'
            }
            iu.msg.error(mensaje)
            console.log(error)
            this.$emit('ticket:actualizado')
            var modal = new Modal(self.$refs.mdlTicket)
            modal.show()
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
          var modal = new Modal(self.$refs.mdlTicket)
          modal.show()
        }
      })
    },

    convertidorSegundos_a_DHM(segundos) {
      var dias = Math.floor(segundos / 86400)
      var horas = Math.floor((segundos / 3600) % 24)
      var minutos = Math.floor((segundos / 60) % 60)
      return [ dias, horas, minutos ]
    },

    masOpciones() {
      var self = this 

      self.$router.push({ name: 'edicionTicketSoporte', params: { id: self.ticket.id } })
      var modal = Modal.getInstance(self.$refs.mdlTicket)
      modal.hide()
    },

    mostrar(idEvento, idTicket) {
      var self = this 

      self.id_ticket = idTicket
      self.id_evento = idEvento
      
      self.cargarTicketSoporte(idTicket)

      self.cargarUsuarios(function() {
        self.cargarEvento(idEvento)
      })

      var modal = new Modal(self.$refs.mdlTicket)
      modal.show()
    },

    sesionesSoporte() {
      var self = this 

      self.$router.push({ name: 'sesionesTicketSoporte', params: { id: self.ticket.id } })
      var modal = Modal.getInstance(self.$refs.mdlTicket)
      modal.hide()
    },

    iniciarSoporte: function() {
      var self = this,
        idTicket = self.ticket.id

      SesionTicketSoporteSrv.iniciar(idTicket).then(response => {
        iu.msg.success('Se inició correctamente la sesión de soporte')
        this.$emit('ticket:actualizado')
        this.cargarTicketSoporte(self.id_ticket)
        self.cargarUsuarios(function() {
          self.cargarEvento(self.id_evento)
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo iniciar la sesión de soporte '+idTicket
        }
        iu.msg.error(mensaje)
        console.log(error)
        this.$emit('ticket:actualizado')
      })
    },
    pausarSoporte: function() {
      var self = this,
          ticket = self.ticket

      var modal = Modal.getInstance(self.$refs.mdlTicket)
      modal.hide()

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      swal.fire({
        title: "¿Deseas pausar el ticket de soporte?",
        html: `¿Está seguro de pausar el ticket de soporte <strong>${ticket.id}</strong>?`,
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.motivoPausa(ticket)
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
          var modal = new Modal(self.$refs.mdlTicket)
          modal.show()
        }
      })
    },
    motivoPausa: function(ticket){
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      swal.fire({
        title: "Motivo de pausa del ticket de soporte",
        html: "Escriba el motivo de pausa del ticket de soporte:",
        icon: "question",
        input: "textarea",
        inputPlaceholder: "Escribe un motivo para pausar el soporte.",
        showCancelButton: true,
        confirmButtonText: "Pausar",
        cancelButtonText: "Cancelar!",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#556ee6",
        cancelButtonColor: "#f46a6a"
      }).then(text => {
        if (text.value) {
          let sesion = { motivo_pausa: text.value }

          SesionTicketSoporteSrv.pausar(ticket.id, sesion).then(response => {
            swal.fire("Pausado!", `La sesión de soporte se ha pausado correctamente, con motivo: <br><br> ${text.value}`, "success");
            this.$emit('ticket:actualizado')
            this.cargarTicketSoporte(self.id_ticket)
            self.cargarUsuarios(function() {
              self.cargarEvento(self.id_evento)
            })
            var modal = new Modal(self.$refs.mdlTicket)
            modal.show()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo pausar el ticket de soporte '+ticket.id
            }
            swal.fire("Error", mensaje, "error");
            console.log(error)
            this.$emit('ticket:actualizado')
            var modal = new Modal(self.$refs.mdlTicket)
            modal.show()
          })
        } else if ( text.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
          var modal = new Modal(self.$refs.mdlTicket)
          modal.show()
        }
      })
    },

    reanudadSoporte: function() {
      var self = this,
          idTicket = self.ticket.id

      SesionTicketSoporteSrv.reanudar(idTicket).then(response => {
        iu.msg.success('Se a reanudado correctamente la sesión de soporte')
        this.$emit('ticket:actualizado')
        this.cargarTicketSoporte(self.id_ticket)
        self.cargarUsuarios(function() {
          self.cargarEvento(self.id_evento)
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo reanudar la sesión de soporte '+idTicket
        }
        iu.msg.error(mensaje)
        console.log(error)
        this.$emit('ticket:actualizado')
      })
    },

    finalizarSoporte: function() {
      var self = this,
          idTicket = self.ticket.id

      var modal = Modal.getInstance(self.$refs.mdlTicket)
      modal.hide()

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      swal.fire({
        title: "¿Deseas finalizar el ticket de soporte?",
        html: `¿Está seguro de finalizar el ticket de soporte <strong>${idTicket}</strong>?`,
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          SesionTicketSoporteSrv.finalizar(idTicket, { id_evento: self.id_evento }).then(response => {
            swal.fire("Finalizado!", "La sesión de soporte se ha finalizado correctamente", "success");
            this.$emit('ticket:actualizado')
            this.cargarTicketSoporte(self.id_ticket)
            self.cargarUsuarios(function() {
              self.cargarEvento(self.id_evento)
            })
            var modal = new Modal(self.$refs.mdlTicket)
            modal.show()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo finalizar el ticket de soporte '+idTicket
            }
            swal.fire("Error", mensaje, "error");
            console.log(error)
            this.$emit('ticket:actualizado')
            var modal = new Modal(self.$refs.mdlTicket)
            modal.show()
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
          var modal = new Modal(self.$refs.mdlTicket)
          modal.show()
        }
      })
    }
  }
}
</script>

<style scoped>
</style>