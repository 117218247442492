<template>
  <div class="modal fade" ref="mdlEvento" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content" style="border:none">
        <div class="modal-header bg-success">
          <h4 class="modal-title text-white">
            {{ evento.editable ? 'Visor de': 'Edición de' }} evento ({{ evento.id }})
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <label>Título del evento</label>
              <input 
                ref="titulo"
                type="text"
                class="form-control"
                v-model="evento.titulo"
                :disabled="evento.editable"
                placeholder="Título"
              >
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label>Descripción del evento</label>
              <textarea
                class="form-control"
                v-model="evento.descripcion"
                :disabled="evento.editable"
                placeholder="Descripción del evento"
              ></textarea>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-6">
              <label>Fecha programada</label>
              <input
                type="datetime-local"
                class="form-control"
                placeholder="YYYY-MM-DD HH:MM:SS"
                v-model="evento.fecha_programada"
                :disabled="evento.editable"
              />
            </div>
            <div class="col-md-6">
              <label>Tiempo estimado</label>
              <div class="row text-center">
                <div class="col-sm-4 col-4">
                  <input
                    name="tiempoDias" type="number"
                    class="form-control text-center" ref="tiempoDias"
                    v-model="evento.tiempo_estimado_dias"
                    :disabled="evento.editable"
                    min="0" max="365"
                    placeholder="0"
                  />
                  <label>Dias</label>
                </div>
                <div class="col-sm-4 col-4">
                  <input
                    name="tiempoHoras" type="number"
                    class="form-control text-center" ref="tiempoHoras"
                    v-model="evento.tiempo_estimado_horas"
                    :disabled="evento.editable"
                    min="0" max="23"
                    placeholder="0"
                  />
                  <label>Horas</label>
                </div>
                <div class="col-sm-4 col-4">
                  <input
                    name="tiempoMinutos" type="number"
                    class="form-control text-center" ref="tiempoMinutos"
                    v-model="evento.tiempo_estimado_minutos" 
                    :disabled="evento.editable"
                    min="0" max="59"
                    placeholder="0"
                  />
                  <label>Minutos</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label>Calendario</label>
              <span class="form-control text-center text-success fw-semibold">
                {{evento.calendario ? evento.calendario.nombre : ''}}
              </span>
            </div>
            <div class="col-md-4">
              <label>Estado</label>
              <select
                name="estado"
                class="form-select"
                v-model="evento.estado"
                :disabled="evento.editable"
                v-show="evento.estado != 4"
                @change="refrescarEventos()"
              >
                <option value="1">Pendiente</option>
                <option value="2">En proceso</option>
                <option value="3">Completado</option>
              </select>
              <div
                class="bg-danger text-white pb-2 pt-2 text-center rounded"
                v-if="evento.estado == 4"
              >
                CANCELADO
              </div>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-3">
              <div class="form-check form-check-success">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="mode"
                  :id="'para-todos-evento-'+evento.id"
                  :checked="evento.todos"
                  @change="evento.todos = !evento.todos"
                  :disabled="evento.editable"
                />
                <label
                  class="form-check-label"
                  :for="'para-todos-evento-'+evento.id"
                >
                  Para todos
                </label>
              </div>
            </div>
            <div class="col-md-9">
              <p v-show="!evento.todos">
                <i class="mdi mdi-arrow-left-thick"></i>
                Si activas esta opción, el evento será
                visible para todos los usuarios y roles.
              </p>
            </div>
          </div>
          <div class="row" v-if="!evento.todos">
            <div class="col-md-6">
              <label for="">Usuarios</label>
              <multiselect
                v-model="evento.ids_usuarios"
                placeholder="Buscar Usuario"
                selectLabel="Presione enter para seleccionar"
                selectedLabel="Seleccionado"
                deselectLabel="Presione enter para eliminar"
                :disabled="evento.editable"
                label="nombre"
                track-by="id"
                :options="usuarios"
                :multiple="true"
                :taggable="false"
              ></multiselect>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
          <button
            class="btn btn-danger"
            v-if="!evento.editable"
            @click="cancelarEvento()"
          >
            <i class="mdi mdi-cancel font-size-15"></i>
            Cancelar
          </button>
          <button
            class="btn btn-success"
            @click="actualizar()"
            v-if="!evento.editable"
            :disabled="bandera_spinner"
          >
            <i
              class="mdi"
              :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
            ></i>
            Actualizar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventoCalendarioSrv from "@/services/EventoCalendarioSrv"
import UsuarioSrv from '@/services/UsuarioSrv.js'
import Modal from 'bootstrap/js/dist/modal'
import Multiselect from 'vue-multiselect'
import Swal from 'sweetalert2'
import moment from 'moment'
export default {
  name: 'MdlEvento',
  components: { Multiselect },
  data() {
    return {
      id_evento: null,
      evento: {
        titulo: '', 
        descripcion: '',
        fecha_programada: '',
        tiempo_estimado: 0,
        tiempo_estimado_dias: 0,
        tiempo_estimado_horas: 1,
        tiempo_estimado_minutos: 0,
        id_calendario: null,
        id_instalacion: null,
        id_soporte: null,
        todos: true, 
        estado: 1,
        editable: true,

        ids_usuarios: []
      },
      usuarios: [],
      bandera_spinner: false
    }
  },
  methods: {
    actualizar() {
      var self = this

      self.bandera_spinner = true

      if(self.evento.titulo == '') {
        iu.msg.warning('se requiere un titulo para el nuevo evento')
        self.$refs.titulo.focus()
        self.bandera_spinner = false
        return
      }

      let total_ids = self.evento.ids_usuarios.length

      if( !self.evento.todos && total_ids == 0 ) {
        iu.msg.warning('Es necesario registrar al menos un "Usuario"')
        self.bandera_spinner = false
        return
      }

      let tiempo = self.calcularDiasASegundos(self.evento.tiempo_estimado_dias) +
                  self.calcularHorasASegundos(self.evento.tiempo_estimado_horas) +
                  self.calcularMinutosASegundos(self.evento.tiempo_estimado_minutos)

      let datos = {
        id: self.evento.id,
        titulo: self.evento.titulo,
        descripcion: self.evento.descripcion,
        fecha_programada: moment(self.evento.fecha_programada, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
        tiempo_estimado: tiempo,
        todos: self.evento.todos,
        estado: self.evento.estado,
        ids_usuarios: self.evento.ids_usuarios
      }

      EventoCalendarioSrv.actualizar(datos).then(response => {
        iu.msg.success('Se actualizó correctamente los datos del soporte de '+self.evento.nombre)
        this.$emit('evento:actualizado')
        this.cargarEvento(self.id_evento)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se actualizo el soporte de '+self.evento.nombre
        }
        iu.msg.error(mensaje)
        console.log(error)
        this.$emit('evento:actualizado')
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    calcularDiasASegundos(dias){
      return dias * 86400
    },
    
    calcularHorasASegundos(horas){
      return horas * 3600
    },

    calcularMinutosASegundos(minutos){
      return minutos * 60
    },

    cancelarEvento: function() {
      var self = this,
          idEvento = self.evento.id

      var modal = Modal.getInstance(self.$refs.mdlEvento)
      modal.hide()

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      swal.fire({
        title: "¿Deseas cancelar el evento?",
        html: `¿Está seguro de cancelar el evento <strong>${idEvento}</strong>?`,
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          EventoCalendarioSrv.cancelar(idEvento).then(response => {
            swal.fire("Cancelado!", "El evento se ha cancelado correctamente", "success");
            this.$emit('evento:actualizado')
            this.cargarEvento(self.id_evento)
            var modal = new Modal(self.$refs.mdlEvento)
            modal.show()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se puede cancelar el evento '+idEvento
            }
            swal.fire("Error", mensaje, "error");
            console.log(error)
            this.$emit('evento:actualizado')
            var modal = new Modal(self.$refs.mdlEvento)
            modal.show()
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
          var modal = new Modal(self.$refs.mdlEvento)
          modal.show()
        }
      })
    },

    cargarEvento: function(idEvento) {
      var self = this
      
      EventoCalendarioSrv.eventoJSON(idEvento, { con_ids_usuarios: true, con_calendario: true }).then(response=>{
        self.evento = response.data

        self.evento.tiempo_estimado_dias = self.convertidorSegundos_a_DHM(self.evento.tiempo_estimado )[0]
        self.evento.tiempo_estimado_horas = self.convertidorSegundos_a_DHM(self.evento.tiempo_estimado )[1]
        self.evento.tiempo_estimado_minutos = self.convertidorSegundos_a_DHM(self.evento.tiempo_estimado )[2]

        self.evento.editable = self.evento.estado == 4 || self.evento.estado == 3 ? true : false
      }).catch(error=>{
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el evento de soporte'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarUsuarios: function(callback) {
      var self = this

      let params = { todos: true }

      UsuarioSrv.usuariosJSON(params).then(response => {
        let usuarios = response.data

        self.usuarios = []
        self.evento.ids_usuarios = []
        usuarios.forEach(usu => {
          let datos = {
            id: usu.id,
            nombre: usu.nombre+' '+(usu.apellido_paterno || '')+' '+(usu.apellido_materno || '')
          }
          self.usuarios.push(datos)
        });
        
        if(callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los usuarios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    convertidorSegundos_a_DHM(segundos) {
      var dias = Math.floor(segundos / 86400)
      var horas = Math.floor((segundos / 3600) % 24)
      var minutos = Math.floor((segundos / 60) % 60)
      return [ dias, horas, minutos ]
    },

    mostrar(idEvento) {
      var self = this

      self.id_evento = idEvento
      self.cargarUsuarios(function() {
        self.cargarEvento(idEvento)
      })

      var modal = new Modal(self.$refs.mdlEvento)
      modal.show()
    }
  }
}
</script>

<style scoped>
</style>