<template>
  <div class="modal fade" ref="mdlInstalacion" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content" style="border:none">
        <div class="modal-header bg-success">
          <h4 class="modal-title text-white">
            Solicitud de instalación ({{ id_instalacion }})
          </h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4" v-show="tieneAlias('Nombre')">
              <label>{{cargarAliasCampo('Nombre')}}</label>
              <input
                class="form-control"
                name="nombre"
                ref="nombre"
                v-model="solicitud.nombre"
                :disabled="!esEditable()"
              />
            </div>
            <div class="col-md-4" v-show="tieneAlias('Apellido paterno')">
              <label>{{cargarAliasCampo('Apellido paterno')}}</label>
              <input
                class="form-control"
                name="apellido_paterno"
                v-model="solicitud.apellido_paterno"
                :disabled="!esEditable()"
              />
            </div>
            <div class="col-md-4" v-show="tieneAlias('Apellido materno')">
              <label>{{cargarAliasCampo('Apellido materno')}}</label>
              <input
                class="form-control"
                name="apellido_materno"
                v-model="solicitud.apellido_materno"
                :disabled="!esEditable()"
              />
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-6">
              <label>Fecha programada</label>
              <input
                type="datetime-local"
                class="form-control"
                placeholder="YYYY-MM-DD HH:MM:SS"
                v-model="solicitud.fecha_programada"
                :disabled="!esEditable()"
              />
            </div>
            <div class="col-md-6">
              <label>Tiempo estimado</label>
              <div class="row text-center">
                <div class="col-sm-4 col-4">
                  <input
                    name="tiempoDias" type="number"
                    class="form-control text-center" ref="tiempoDias"
                    v-model="solicitud.tiempo_estimado_dias"
                    min="0" max="365"
                    placeholder="0"
                    :disabled="!esEditable()"
                  />
                  <label>Dias</label>
                </div>
                <div class="col-sm-4 col-4">
                  <input
                    name="tiempoHoras" type="number"
                    class="form-control text-center" ref="tiempoHoras"
                    v-model="solicitud.tiempo_estimado_horas"
                    min="0" max="23"
                    placeholder="0"
                    :disabled="!esEditable()"
                  />
                  <label>Horas</label>
                </div>
                <div class="col-sm-4 col-4">
                  <input
                    name="tiempoMinutos" type="number"
                    class="form-control text-center" ref="tiempoMinutos"
                    v-model="solicitud.tiempo_estimado_minutos" 
                    min="0" max="59"
                    placeholder="0"
                    :disabled="!esEditable()"
                  />
                  <label>Minutos</label>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label>Servicio a contratar</label>
              <select
                class="form-select"
                name="id_servicio"
                ref="id_servicio"
                v-model="solicitud.id_servicio"
                :disabled="!esEditable()"
              >
                <option :value="null">Selecionar servicio</option>
                <option v-for="plan in planes" :key="plan.id" :value="plan.id">
                  {{ plan.nombre }}
                </option>
              </select>
            </div>
            <div class="col-md-4">
              <label>Meses incluidos</label>
              <div class="input-group" style="z-index: 1">
                <input
                  ref="meses"
                  type="number"
                  v-model="solicitud.meses_incluidos"
                  min="0" max="60"
                  class="form-control text-right"
                  placeholder="0"
                  :disabled="!esEditable()"
                />
                <span class="input-group-text">
                  mes{{ solicitud.meses_incluidos > 1 || solicitud.meses_incluidos == 0 ? 'es' : '' }}
                </span>
              </div>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-4">
              <label>Precio de instalación</label>
              <div class="input-group" style="z-index: 1">
                <span class="input-group-text">
                  <i class="bx bx-dollar"></i>
                </span>
                <input
                  ref="importe"
                  type="number"
                  step="0.01"
                  min="0"
                  v-model="solicitud.importe"
                  oninput="this.value = Math.abs(this.value)"
                  class="form-control text-right"
                  placeholder="0.00"
                  :disabled="!esEditable()"
                />
              </div>
            </div>
            <div class="col-md-4">
              <label>Anticipo</label>
              <div class="input-group">
                <span class="input-group-text">
                  <i class="bx bx-dollar"></i>
                </span>
                <input
                  ref="anticipo"
                  type="number"
                  step="0.01"
                  min="0"
                  v-model="solicitud.anticipo"
                  oninput="this.value = Math.abs(this.value)"
                  class="form-control text-right"
                  placeholder="0.00"
                  :disabled="!esEditable()"
                />
              </div>
            </div>
            <div class="col-md-4">
              <label>Método de pago</label>
              <div class="input-group">
                <span class="input-group-text">
                  <i class="bx bx-dollar"></i>
                </span>
                <select
                  v-model="solicitud.id_metodo_pago"
                  :disabled="!esEditable()"
                  class="form-select"
                >
                  <option v-for="metodoPago in metodos_pago" 
                    :value="metodoPago.id"
                    :key="metodoPago.id">
                    {{metodoPago.nombre}}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-4">
              <label>Estado de la solicitud</label>
              <select
                name="id_estado"
                class="form-select"
                :class="{
                  'text-warning': solicitud.id_estado == 1,
                  'text-info': solicitud.id_estado == 2,
                  'text-primary': solicitud.id_estado == 3
                }"
                style="font-weight: bold"
                v-model="solicitud.id_estado"
                :disabled="!esEditable()"
                v-if="solicitud.id_estado != 4"
              >
                <option :value="1" selected>
                  PENDIENTE
                </option>
                <option :value="2">EN PROCESO</option>
                <option :value="3">COMPLETADA</option>
              </select>
              <div
                class="bg-danger text-white pb-2 pt-2 text-center rounded"
                v-if="solicitud.id_estado == 4"
              >
                CANCELADA
              </div>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-3">
              <div class="form-check form-check-success">
                <input
                  class="form-check-input"
                  type="checkbox"
                  name="mode"
                  :id="'para-todos-instalacion-'+evento.id"
                  :checked="evento.todos"
                  @change="evento.todos = !evento.todos"
                  :disabled="evento.editable"
                />
                <label
                  class="form-check-label"
                  :for="'para-todos-instalacion-'+evento.id"
                >
                  Para todos
                </label>
              </div>
            </div>
            <div class="col-md-9">
              <p v-show="!evento.todos">
                <i class="mdi mdi-arrow-left-thick"></i>
                Si activas esta opción, el evento será
                visible para todos los usuarios y roles.
              </p>
            </div>
          </div>
          <div class="row" v-if="!evento.todos">
            <div class="col-md-6">
              <label for="">Usuarios</label>
              <multiselect
                v-model="evento.ids_usuarios"
                placeholder="Buscar Usuario"
                selectLabel="Presione enter para seleccionar"
                selectedLabel="Seleccionado"
                deselectLabel="Presione enter para eliminar"
                :disabled="evento.editable"
                label="nombre"
                track-by="id"
                :options="usuarios"
                :multiple="true"
                :taggable="false"
              ></multiselect>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="masOpciones()"
            class="btn btn-default text-dark"
            v-if="solicitud.id != null"
          >
            Más opciones
          </button>
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            Cerrar
          </button>
          <button
            class="btn btn-danger"
            @click="preguntaCancelarSolicitud()"
            v-if="!(solicitud.id_estado == 3 || solicitud.id_estado == 4)"
          >
            <i class="mdi mdi-cancel font-size-13"></i>
            Cancelar
          </button>
          <button
            class="btn btn-success"
            @click="actualizar()"
            v-if="esEditable()"
            :disabled="bandera_spinner"
          >
            <i
              class="mdi"
              :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
            ></i>
            Actualizar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SolicitudInstalacionSrv from '@/services/SolicitudInstalacionSrv.js'
import EventoCalendarioSrv from "@/services/EventoCalendarioSrv"
import MetodoPagoSrv from '@/services/MetodoPagoSrv.js'
import ProductoSrv from '@/services/ProductoSrv.js'
import UsuarioSrv from '@/services/UsuarioSrv.js'
import { todoGetters } from "@/state/helpers"
import Modal from 'bootstrap/js/dist/modal'
import Multiselect from 'vue-multiselect'
import Swal from 'sweetalert2'
import moment from 'moment'

export default {
  name: 'MdlInstalacion',
  components: { Multiselect },
  data() {
    return {
      id_evento: null,
      id_instalacion: null,

      evento: {
        titulo: '', 
        descripcion: '',
        fecha_programada: '',
        tiempo_estimado: 0,
        tiempo_estimado_dias: 0,
        tiempo_estimado_horas: 1,
        tiempo_estimado_minutos: 0,
        id_calendario: null,
        id_instalacion: null,
        id_soporte: null,
        todos: true, 
        estado: 1,
        editable: true,

        ids_usuarios: []
      },

      solicitud: {
        id: null,

        id_estado: 1,
        nuevo_id_estado: 1, // Se usa para determinar si hubo una actualización o no

        id_servicio: null,
        meses_incluidos: 0,
        anticipo: 0,
        importe: 0,
        id_metodo_pago: 1,   // Efectivo
        motivo_cancelacion: '',

        nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        sexo: 'hombre',
        rfc: '',
        telefono: '',
        correo: '',
        empresa: 0,

        calle: '',
        numero_exterior: '',
        numero_interior: '',
        colonia: '',
        municipio: '',
        codigo_postal: '',
        estado_residencia: '',
        pais: '',

        calle_instalacion: '',
        numero_exterior_instalacion: '',
        numero_interior_instalacion: '',
        colonia_instalacion: '',
        municipio_instalacion: '',
        codigo_postal_instalacion: '',
        estado_residencia_instalacion: '',
        pais_instalacion: '',

        latitud: 0,
        longitud: 0,

        referencias: '',
        descripcion_casa: '',

        fecha_programada: '',
        tiempo_estimado: 0,
        tiempo_estimado_dias: 0,
        tiempo_estimado_horas: 1,
        tiempo_estimado_minutos: 0,

        telefonos: [],

        editable: true
      },

      planes: [],
      metodos_pago: [],
      usuarios: [],

      bandera_spinner: false,
    }
  },
  computed: {
    ...todoGetters,
  },
  methods: {
    actualizar: function() {
      var self = this 
      self.actualizarEvento(function() {
        self.actualizarInstalacion()
      })
    },

    actualizarInstalacion: function() {
      var self = this
      self.bandera_spinner = true

      var solicitud = Object.assign({}, self.solicitud)

      // convertimos el formato fecha al formato de fecha deseado
      solicitud.fecha_programada = self.formatoFecha(solicitud.fecha_programada , 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss')
      solicitud.tiempo_estimado = self.calcularDiasASegundos(solicitud.tiempo_estimado_dias) +
                                    self.calcularHorasASegundos(solicitud.tiempo_estimado_horas) +
                                    self.calcularMinutosASegundos(solicitud.tiempo_estimado_minutos)

      if(solicitud.tiempo_estimado == 0){
        iu.msg.warning('No se puede guardar el ticket de soporte porque se necesita un tiempo estimado')
        self.$refs.tiempoMinutos.focus()
        self.bandera_spinner = false
        return
      }

      SolicitudInstalacionSrv.actualizar(solicitud).then(response => {
        iu.msg.success('Se actualizó correctamente los datos de la solicitud de instalacion de '+self.solicitud.id)
        this.$emit('instalacion:actualizado')
        this.cargarInstalacion(self.id_instalacion)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar la solicitud de instalación'
        }
        iu.msg.error(mensaje)
        console.log(error)
        this.$emit('instalacion:actualizado')
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    actualizarEvento(callback) {
      var self = this

      self.bandera_spinner = true

      let total_ids = self.evento.ids_usuarios.length

      if( !self.evento.todos && total_ids == 0 ) {
        iu.msg.warning('Es necesario registrar al menos un "Usuario"')
        self.bandera_spinner = false
        return
      }

      let tiempo = self.calcularDiasASegundos(self.evento.tiempo_estimado_dias) +
                  self.calcularHorasASegundos(self.evento.tiempo_estimado_horas) +
                  self.calcularMinutosASegundos(self.evento.tiempo_estimado_minutos)

      let datos = {
        id: self.evento.id,
        fecha_programada: moment(self.evento.fecha_programada, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
        tiempo_estimado: tiempo,
        todos: self.evento.todos,
        ids_usuarios: self.evento.ids_usuarios
      }

      EventoCalendarioSrv.actualizar(datos).then(response => {
        this.cargarEvento(self.id_evento)
        if(callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se actualizo el soporte de '+self.evento.nombre
        }
        iu.msg.error(mensaje)
        console.log(error)
        this.$emit('evento:actualizado')
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    calcularDiasASegundos(dias){
      return dias * 86400
    },
    
    calcularHorasASegundos(horas){
      return horas * 3600
    },

    calcularMinutosASegundos(minutos){
      return minutos * 60
    },

    formatoFecha(fecha, formatoFecha = 'YYYY-MM-DD', formatoConvertir = 'DD/MM/YYYY') {
      return moment(fecha, formatoFecha).format(formatoConvertir)
    },

    cargarEvento: function(idEvento) {
      var self = this
      
      EventoCalendarioSrv.eventoJSON(idEvento, { con_ids_usuarios: true, con_calendario: true }).then(response=>{
        self.evento = response.data

        self.evento.tiempo_estimado_dias = self.convertidorSegundos_a_DHM(self.evento.tiempo_estimado )[0]
        self.evento.tiempo_estimado_horas = self.convertidorSegundos_a_DHM(self.evento.tiempo_estimado )[1]
        self.evento.tiempo_estimado_minutos = self.convertidorSegundos_a_DHM(self.evento.tiempo_estimado )[2]

        self.evento.editable = self.evento.estado == 4 || self.evento.estado == 3 ? true : false
      }).catch(error=>{
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el evento de soporte'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarInstalacion: function(idInstalacion) {
      var self = this
      
      SolicitudInstalacionSrv.solicitudInstalacionJSON(idInstalacion).then(response=>{
        self.solicitud = response.data

        self.solicitud.tiempo_estimado_dias = self.convertidorSegundos_a_DHM(self.solicitud.tiempo_estimado )[0]
        self.solicitud.tiempo_estimado_horas = self.convertidorSegundos_a_DHM(self.solicitud.tiempo_estimado )[1]
        self.solicitud.tiempo_estimado_minutos = self.convertidorSegundos_a_DHM(self.solicitud.tiempo_estimado )[2]

        self.solicitud.editable = !(self.solicitud.id_estado == 3 || self.solicitud.id_estado == 4) ? true : false
      }).catch(error=>{
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el ticket de soporte'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarMetodosPago () {
      let self = this

      MetodoPagoSrv.metodosJSON().then(response => {
        self.metodos_pago = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    },

    cargarPlanes: function() {
      var self = this
      let params = { paginacion: false, activo: true }

      return ProductoSrv.planesServicioJSON(params).then(response => {
        self.planes = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje): null
        console.log(error)
      })
    },

    cargarUsuarios: function(callback) {
      var self = this

      let params = { todos: true }

      UsuarioSrv.usuariosJSON(params).then(response => {
        let usuarios = response.data

        self.usuarios = []
        self.evento.ids_usuarios = []
        usuarios.forEach(usu => {
          let datos = {
            id: usu.id,
            nombre: usu.nombre+' '+(usu.apellido_paterno || '')+' '+(usu.apellido_materno || '')
          }
          self.usuarios.push(datos)
        });
        
        if(callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los usuarios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    convertidorSegundos_a_DHM(segundos) {
      var dias = Math.floor(segundos / 86400)
      var horas = Math.floor((segundos / 3600) % 24)
      var minutos = Math.floor((segundos / 60) % 60)
      return [ dias, horas, minutos ]
    },

    esEditable: function() {
      var self = this
      return self.solicitud.editable
    },

    masOpciones() {
      var self = this 

      self.$router.push({ name: 'edicionSolicitudInstalacion', params: { id: self.id_instalacion } })
      var modal = Modal.getInstance(self.$refs.mdlInstalacion)
      modal.hide()
    },

    preguntaCancelarSolicitud: function() {
      var self = this,
        idSolicitud = self.solicitud.id

      var modal = Modal.getInstance(self.$refs.mdlInstalacion)
      modal.hide()
      
      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      swal.fire({
        title: "Cancelación de solicitud",
        html: '¿Desea cancelar la solicitud <strong>' + idSolicitud + '</strong> ?',
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.preguntaMotivoCancelacion(idSolicitud)
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
          var modal = new Modal(self.$refs.mdlInstalacion)
          modal.show()
        }
      })
    },

    preguntaMotivoCancelacion: function(idSolicitud) {
      var self = this
      
      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      });

      swal.fire({
        title: "Motivo de cancelación",
        html: '¿Por qué se canceló la instalación?',
        input: "textarea",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "cancelar!",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#556ee6",
        cancelButtonColor: "#f46a6a"
      }).then(text => {
        if (text.value) {
          self.cancelarSolicitud(idSolicitud, text.value)
        } else if ( text.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
          var modal = new Modal(self.$refs.mdlInstalacion)
          modal.show()
        }
      })
    },

    cancelarSolicitud: function(idSolicitud, motivo) {
      var self = this

      SolicitudInstalacionSrv.cancelar(idSolicitud, motivo).then(response => {
        iu.msg.success('Se canceló correctamente')
        this.$emit('instalacion:actualizado')
        
        self.cargarInstalacion(self.id_instalacion)
        self.cargarUsuarios(function() {
          self.cargarEvento(self.id_evento)
        })
        var modal = new Modal(self.$refs.mdlInstalacion)
        modal.show()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se puede cancelar la solicitud'
        }
        iu.msg.error(mensaje)
        console.log(error)
        this.$emit('instalacion:actualizado')
        var modal = new Modal(self.$refs.mdlInstalacion)
        modal.show()
      })
    },

    mostrar(idEvento, idInstalacion) {
      var self = this 

      self.id_instalacion = idInstalacion
      self.id_evento = idEvento

      self.cargarPlanes()
      self.cargarMetodosPago()
      self.cargarInstalacion(idInstalacion)
      
      self.cargarUsuarios(function() {
        self.cargarEvento(idEvento)
      })

      var modal = new Modal(self.$refs.mdlInstalacion)
      modal.show()
    }
  }
}
</script>

<style scoped>
</style>